import { HashLink } from "react-router-hash-link";
import "./App.css";
import AboutMe from "./components/AboutMe";
import ContactMe from "./components/ContactMe";
import Home from "./components/Home";
import ProjectsLegacy from "./components/ProjectsLegacy";
import TechStack from "./components/TechStack";
// import Work from "./components/Work";

function App() {
  return (
    <>
      <Home />
      <ProjectsLegacy />
      {/* <Work /> */}
      <AboutMe />
      <TechStack />
      <ContactMe />
      <div type="button" className="top-btn">
        <HashLink to="#home" smooth>
          <span className="dot"></span>
        </HashLink>
      </div>
    </>
  );
}

export default App;
