import React from "react";

import styles from "./contactMe.module.css";

import { BsLinkedin, BsGithub } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { useMediaQuery } from "react-responsive";

const emailHandler = () => {
  window.open(
    "mailto:benhodev@gmail.com?subject=Hire Me&body=Body%20goes%20here"
  );
};

const linkedinHandler = () => {
  window.open("https://www.linkedin.com/in/ho-ben/");
};

const githubHandler = () => {
  window.open("https://github.com/bennyhch");
};

function BigButton({ title, clickhandler, icon }) {
  return (
    <div
      className={styles.btn}
      onClick={clickhandler}
      aria-label={`Open ${title}`}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <div className={styles.iconContainer}>
        <div>{icon}</div>
        <div className={styles.text}>{title}</div>
      </div>
    </div>
  );
}

const ContactMe = () => {
  const isEightFiftyPx = useMediaQuery({ query: "(min-width: 850px)" });

  return (
    <article id="contactme" className={`${styles.contact} odd-page`}>
      <header>
        <h1>Let's connect!</h1>
        {isEightFiftyPx && (
          <p>
            Feel free to check out more about my other projects on Github or
            shoot me a text at benhodev@gmail.com
          </p>
        )}
        <a
          style={{ color: "#faf3dd", fontSize: "20px" }}
          href={require("../assets/Resume_BenHo.pdf")}
          download="Resume_BenHo"
        >
          [Résumé]
        </a>
      </header>
      <section className={styles.btnContainer}>
        <BigButton
          title="Email"
          clickhandler={emailHandler}
          icon={<MdEmail />}
        />

        <BigButton
          title="Linkedin"
          clickhandler={linkedinHandler}
          icon={<BsLinkedin />}
        />

        <BigButton
          title="GitHub"
          clickhandler={githubHandler}
          icon={<BsGithub />}
        />
      </section>
    </article>
  );
};

export default ContactMe;
