import React, { useEffect } from "react";
import styles from "./projects.module.css";
import { BsGithub } from "react-icons/bs";
import { projectsArr } from "../data/projects";
import Aos from "aos";
import "aos/dist/aos.css";

const Projects = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const githubHandler = (gLink) => {
    window.open(gLink);
  };

  return (
    <main id="projects" className={`${styles.projectsContainer} even-page`}>
      <h1>Selected Projects </h1>

      <div className={styles.headingUnderline}></div>

      <article className={styles.contentContainer}>
        {projectsArr.map((project) => {
          const { id, image, name, description, githubLink, tech } = project;
          return (
            <section className={styles.imgContainer} key={id}>
              <div className={styles.temp}>
                <img
                  src={image}
                  alt={name}
                  className={styles.imgImage}
                  data-aos="fade-down-left"
                />
              </div>
              <div data-aos="fade-down-right" className={styles.textDetails}>
                <h2>
                  <span>{name}</span>
                  <span>
                    <BsGithub
                      onClick={githubHandler.bind(null, githubLink)}
                      size={25}
                      className={styles.icons}
                    />
                  </span>
                </h2>

                <p>{description}</p>
                <div className={styles.techStack}>
                  {tech.map((el, index) => {
                    return (
                      <span className={styles.techEl} key={index}>
                        {el}
                      </span>
                    );
                  })}
                </div>
              </div>
            </section>
          );
        })}
      </article>
    </main>
  );
};

export default Projects;
