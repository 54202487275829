import React from "react";

import styles from "./home.module.css";
import NavBar from "./utils/NavBar";
import WordCloud from "./utils/WordCloud";

import Typewriter from "typewriter-effect";

const Home = () => {
  return (
    <article id="home" className={`${styles.homeContainer} odd-page`}>
      <NavBar />
      <section className={styles.mainContent}>
        <WordCloud />
        <h1>
          <Typewriter
            component={"span"}
            options={{
              strings: ["Hi, my name's Ben Ho", "I'm a frontend engineer"],
              autoStart: true,
              loop: true,
            }}
          />
        </h1>
      </section>
    </article>
  );
};

export default Home;
