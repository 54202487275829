import React, { useEffect, useRef } from "react";
import TagCloud from "TagCloud";
import { useMediaQuery } from "react-responsive";
import styles from "./wordcloud.module.css";

const Tags = [
  "JavaScript",
  "CSS",
  "HTML",
  "React",
  "Angular",
  "jQuery",
  "Cypress",
  "Git",
  "Sass",
  "TypeScript",
  "Redux",
  "REST",
  "Express",
  "JSON",
  "Figma",
  "Node.js",
  "Koa",
  "MongoDB",
  "Mongoose",
  "PostgreSQL",
  "Material-UI",
  "Bootstrap",
];
let cloudRadius = 150;
const WordCloud = () => {
  const isSevenSixtyPx = useMediaQuery({ query: "(min-width: 760px)" });
  if (isSevenSixtyPx) {
    cloudRadius = 250;
  }

  const isEighteenThousandPx = useMediaQuery({ query: "(min-width: 1600px)" });
  if (isEighteenThousandPx) {
    cloudRadius = 350;
  }

  const IsTagCloudLoaded = useRef(false);

  useEffect(() => {
    if (IsTagCloudLoaded.current) return;

    TagCloud(".content", Tags, {
      radius: cloudRadius,
      maxSpeed: "fast",
      initSpeed: "fast",
      direction: 135,
      keep: true,
    });

    IsTagCloudLoaded.current = true;
  }, []);

  return <div className={`content ${styles.cloud}`}></div>;
};

export default WordCloud;
