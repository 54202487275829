import React from "react";
import me from "../assets/mudshot.png";
import styles from "./aboutme.module.css";
import Typewriter from "typewriter-effect";

import { MdArrowOutward } from "react-icons/md";

const fiveApp = [
  "Refactored and updated legacy codebases, transitioning from JavaScript to TypeScript, which improved code quality and maintainability",
  "Debugged and resolved application issues, enhancing UI/UX and user satisfaction",
  "Developed reusable custom hooks and UI components with ReactJS, documented with tools such as Storybook, increasing team productivity",
  "Conducted testings with tools such as Cypress, ensuring web application reliability and functionality",
  "Collaborated with senior developers to implement accessibility standards, improving app inclusivity",
  "Tech Stack: ReactJS, TypeScript, React-Query, Styled Components, SQL, Storybook, Cypress",
];

const clickHandler = () => {
  window.open("https://5app.com/");
};

const AboutMe = () => {
  return (
    <article id="aboutme" className={`${styles.aboutContainer} odd-page`}>
      <h2>
        <Typewriter
          options={{
            strings: ["Id", "Ego", "Superego"],
            autoStart: true,
            loop: true,
          }}
        />
      </h2>
      <main>
        <section className={styles.textContainer}>
          <h3
            className={styles.textHeading}
            onClick={clickHandler}
            role="button"
            tabIndex="0"
            aria-label="Open 5app website"
            onKeyDown={(e) => {
              if (e.key) {
                clickHandler();
              }
            }}
          >
            Frontend Engineer &#183; 5app (London)
            <span className={styles.arrowContainer}>
              <MdArrowOutward size={20} />
            </span>
          </h3>
          <div>
            <em>2022 - Present</em>
          </div>
          <ul>
            {fiveApp.map((bullet, i) => (
              <li key={i}>{bullet}</li>
            ))}
          </ul>
        </section>
        <section className={styles.imgContainer}>
          <img src={me} alt="Photograph of me" />
        </section>
      </main>
    </article>
  );
};

export default AboutMe;

/* 
          <p>
            I'm a front-end developer based in London, UK. Originally from the
            bustling city of Hong Kong, I moved to California to earn my degree
            in Psychology at Berkeley.
          </p>

          <p>
            My love affair with programming began unexpectedly during my time as
            a researcher at university. Tasked with transcribing and extracting
            data from the internet, I spent countless weeks (maybe even months)
            manually crunching numbers. That is, until I discovered that a few
            lines of code could do it all in seconds... &#128529;
          </p>

          <p>
            Before I developed a "psychological bond" with coding (some might
            call it Stockholm Syndrome), I worked with individuals with
            emotional & learning difficulties. So, yes, I went from managing
            meltdowns to debugging code—turns out, both require a lot of
            patience!
          </p>

          <p>
            Interests: Reading detective stories, cheering for AC Milan, and
            occasionally pretending to be Sherlock Holmes of the coding world.
          </p> */
