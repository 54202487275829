export const projectsArr = [
  {
    id: 1,
    // image: require("../assets/landscape2.png"),
    image: require("../assets/dashboard-moodify.png"),
    name: "Moodify",
    description:
      "A mood-tracking and journaling app for users to keep track of their mood throughout the days.",
    githubLink: "https://github.com/bennyhch/Moodify-Client",
    url: "https://tourmaline-chimera-ef3d1e.netlify.app/",
    tech: ["MERN Stack", "JWT", "Cypress", "Redux Toolkit", "Material-UI"],
  },
  {
    id: 2,
    name: "No-Spoilers!",
    image: require("../assets/chatbox.png"),
    description:
      "No Spoilers! allows users to discuss the TV Shows they've seen in a spoiler-free platform (i.e., forum and live chat).",
    githubLink: "https://github.com/bennyhch/No-Spoilers-Client",
    url: null,
    tech: ["Redux", "MERN", "Socket.IO", "TypeScript", "Styled Components"],
  },
];
