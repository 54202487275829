import React from "react";
import { HashLink } from "react-router-hash-link";
import styles from "./navbar.module.css";
import { MdPersonOutline } from "react-icons/md";
import { RiComputerLine } from "react-icons/ri";
import { HiOutlineMailOpen } from "react-icons/hi";
import { VscTools } from "react-icons/vsc";

function Wrapper({ to, children }) {
  return (
    <section className={styles.box}>
      <HashLink
        to={to}
        smooth
        className={styles.link}
        role="link"
        aria-label={`Navigate to ${to}`}
      >
        {children}
      </HashLink>
    </section>
  );
}

const NavBar = () => {
  const links = [
    {
      to: "#projects",
      icon: <RiComputerLine className={styles.icon} aria-hidden="true" />,
      text: "Work",
    },
    {
      to: "#aboutme",
      icon: <MdPersonOutline className={styles.icon} aria-hidden="true" />,
      text: "About",
    },
    {
      to: "#techstack",
      icon: <VscTools className={styles.icon} aria-hidden="true" />,
      text: "Skills",
    },
    {
      to: "#contactme",
      icon: <HiOutlineMailOpen className={styles.icon} aria-hidden="true" />,
      text: "Contact",
    },
  ];

  return (
    <nav
      className={styles.navContainer}
      role="navigation"
      aria-label="Main Navigation"
    >
      {links.map(({ to, icon, text }) => (
        <Wrapper key={to} to={to}>
          {icon}
          <div className={styles.wrd}>{text}</div>
        </Wrapper>
      ))}
    </nav>
  );
};

export default NavBar;
